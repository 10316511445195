import React from "react";
import { RiMenuLine } from "react-icons/ri";
import '../../css/header.css';



const Header = () => {
    
    return(
        <>
            <header id="header-desktop">
                <nav className="sizer">
                    <ul>
                        <li className="logo">                
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="31px"
                            height="24px"
                            viewBox="0 0 31 20"
                            version="1.1"
                        >
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g fill="#23D63E">
                                <g>
                                <path d="M8.91699825,9.14352066 C8.91699825,7.59440649 11.2748222,6.37269638 13.0933785,6.37269638 C14.5105699,6.37269638 15.1627702,7.00242917 15.1627702,7.88405508 C15.1627702,9.43316925 12.8049462,10.6548794 10.9863899,10.6548794 C9.56919854,10.6548794 8.91699825,10.0251466 8.91699825,9.14352066 L8.91699825,9.14352066 Z M22.7879948,7.84629961 C22.7879948,4.69763565 20.5304766,2.20392229 14.9119349,2.20392229 C8.54074521,2.20392229 2.0818767,6.23421216 2.0818767,11.0579368 C2.0818767,14.2694316 4.94137137,16.4734964 9.89522775,16.4734964 C14.849226,16.4734964 18.9000467,14.584298 21.533818,11.9646381 L27.9677165,14.4583514 C24.2930069,17.8336907 18.1475407,20 12.7422374,20 C4.45218568,20 0,15.554115 0,11.4230964 C0,5.66745265 7.47469495,0 16.7930581,0 C25.4468779,0 30.0620782,4.13087614 29.9993693,7.84629961 L22.7879948,7.84629961 Z" />
                                </g>
                            </g>
                            </g>
                        </svg>
                        </li>

                        <li>Aide à la conduite</li>
                        <li>Récupération après-vol</li>
                        <li>Nos magasins</li>
                        <li>Coyote Business</li>
                        <li>À propos</li>
                    </ul>

                    <ul>
                        <li><i class="bi bi-save"></i><span>Enregistrer<br></br>un boîtier</span></li>
                        <li><i class="bi bi-chat"></i><span>Assistance</span></li>
                        <li><i class="bi bi-bag"></i><span>Panier</span></li>
                        <li><button>Se connecter</button></li>
                    </ul>
                </nav>
            </header>



            <header id="header-mobile">
                <ul>
                    <li><RiMenuLine id="bar" /></li>
                    <li>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="31px"
                            height="24px"
                            viewBox="0 0 31 20"
                            version="1.1"
                        >
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <g fill="#23D63E">
                                <g>
                                <path d="M8.91699825,9.14352066 C8.91699825,7.59440649 11.2748222,6.37269638 13.0933785,6.37269638 C14.5105699,6.37269638 15.1627702,7.00242917 15.1627702,7.88405508 C15.1627702,9.43316925 12.8049462,10.6548794 10.9863899,10.6548794 C9.56919854,10.6548794 8.91699825,10.0251466 8.91699825,9.14352066 L8.91699825,9.14352066 Z M22.7879948,7.84629961 C22.7879948,4.69763565 20.5304766,2.20392229 14.9119349,2.20392229 C8.54074521,2.20392229 2.0818767,6.23421216 2.0818767,11.0579368 C2.0818767,14.2694316 4.94137137,16.4734964 9.89522775,16.4734964 C14.849226,16.4734964 18.9000467,14.584298 21.533818,11.9646381 L27.9677165,14.4583514 C24.2930069,17.8336907 18.1475407,20 12.7422374,20 C4.45218568,20 0,15.554115 0,11.4230964 C0,5.66745265 7.47469495,0 16.7930581,0 C25.4468779,0 30.0620782,4.13087614 29.9993693,7.84629961 L22.7879948,7.84629961 Z" />
                                </g>
                            </g>
                            </g>
                        </svg>
                    </li>
                    <div>
                        <li><i class="bi bi-bag"></i></li>
                        <li><i id="personne" class="bi bi-person"></i></li>
                    </div>
                </ul>

            </header>



        </>
    )

};

export default Header;