import React from "react";
import "../../css/footer.css"

const Footer = () => {
    return(
        <>
            <footer>
                <nav>
                    <section>
                        <svg xmlns="http://www.w3.org/2000/svg" width="130" height="50" viewBox="0 0 190 50" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.9146 23.5729C19.9146 20.0873 25.1802 17.3385 29.2418 17.3385C32.4067 17.3385 33.8635 18.7556 33.8635 20.7394C33.8635 24.2244 28.5972 26.9732 24.5363 26.9732C21.3713 26.9732 19.9146 25.5567 19.9146 23.5729ZM50.8929 20.654C50.8929 13.5698 45.8516 7.95886 33.3033 7.95886C19.0741 7.95886 4.64943 17.0268 4.64943 27.8803C4.64943 35.106 11.0354 40.0655 22.0991 40.0655C33.1628 40.0655 42.2103 35.8148 48.0922 29.9208L62.4607 35.5312C54.2544 43.1259 40.5298 48 28.4573 48C9.94343 48 0 37.9969 0 28.702C0 15.7519 16.6936 3 37.5048 3C56.8309 3 67.1385 12.2949 66.9986 20.654H50.8929Z" fill="#23D63E"></path>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M139.507 20C145.148 20 148.095 22.6718 148.095 26.9418C148.095 31.2694 145.148 34 139.507 34C133.865 34 130.919 31.2694 130.919 26.9418C130.919 22.6137 133.865 20 139.507 20ZM101.505 20C107.146 20 110.093 22.6718 110.093 26.9418C110.093 31.2694 107.146 34 101.505 34C95.8638 34 92.9166 31.2694 92.9166 26.9418C92.9166 22.6137 95.8638 20 101.505 20ZM82.5602 20C85.4228 20 87.8365 20.5515 90.1663 22.0328L88.3978 24.8797C86.4892 23.6885 84.525 23.1658 82.5318 23.1658C78.9115 23.1658 77.4801 24.502 77.4801 26.9418C77.4801 29.3817 78.9115 30.8342 82.5318 30.8342C84.7491 30.8342 86.6016 30.4853 88.7628 29.1491L90.5312 31.7634C88.4818 33.2159 85.7877 34 82.5602 34C76.975 34 74 31.2694 74 26.9418C74 22.6137 76.9466 20 82.5602 20ZM181.888 20.0869V23.0495H172.12V25.4312H177.93V28.2781H172.12V30.9211H182V33.8837H168.753V20.0869H181.888ZM165.778 20.1157V23.0789H159.715V33.8837H156.234V23.0789H150.173V20.1157H165.778ZM115.117 20.0872C116.577 22.2362 118.401 24.2115 120.534 26.0123C122.667 24.1821 124.519 22.2362 126.091 20.0872H130.02C127.831 23.1367 125.221 26.0123 122.19 28.5684V33.8546H118.794V28.5972C115.763 26.1286 113.153 23.253 110.963 20.0872H115.117ZM139.535 23.1658C135.943 23.1658 134.455 24.502 134.455 26.9418C134.455 29.3817 135.943 30.8342 139.535 30.8342C143.128 30.8342 144.615 29.4398 144.615 26.9418C144.615 24.502 143.128 23.1658 139.535 23.1658ZM101.533 23.1658C97.9404 23.1658 96.4529 24.502 96.4529 26.9418C96.4529 29.3817 97.9404 30.8342 101.533 30.8342C105.125 30.8342 106.613 29.4398 106.613 26.9418C106.613 24.502 105.125 23.1658 101.533 23.1658Z" fill="white"></path>
                        </svg>
                    </section>

                    <section className="sizer">
                        <ul>
                            <li><h1>L’UNIVERS COYOTE</h1></li>
                            <li><span>Tarifs abonnements</span></li>
                            <li><span>Tous les produits</span></li>
                            <li><span>Comparer nos solutions</span></li>
                            <li><span>Alerte sécurite prédictive</span></li>
                            <li><span>Dossier radars</span></li>
                            <li><span>Dossier permis de conduire</span></li>
                            <li><span>Dossier amendes</span></li>
                            <li><span>Dossier antivol voiture</span></li>
                            <li><span>Dossier antivol moto</span></li>
                            <li><span>Dossier détecteur de radar</span></li>
                            <li><span>Dossier contester une amende</span></li>
                            <li><span>Limitation à 80km/h</span></li>
                            <li><span>La route Napoléon</span></li>
                            <li><span>Le col du Stelvio</span></li>
                            <li><span>La route des Grandes Alpes</span></li>
                            <li><span>Observatoire des vols Coyote</span></li>
                        </ul>

                        <ul>
                            <li><h1>INFO TRAFIC</h1></li>
                            <li><span>Info trafic en temps réel à Bordeaux</span></li>
                            <li><span>Info trafic en temps réel à Lille</span></li>
                            <li><span>Info trafic en temps réel à Lyon</span></li>
                            <li><span>Info trafic en temps réel à Marseille</span></li>
                            <li><span>Info trafic en temps réel à Montpellier</span></li>
                            <li><span>Info trafic en temps réel à Nantes</span></li>
                            <li><span>Info trafic en temps réel à Nice</span></li>
                            <li><span>Info trafic en temps réel à Paris</span></li>
                            <li><span>Info trafic en temps réel à Strasbourg</span></li>
                            <li><span>Info trafic en temps réel à Toulouse</span></li>
                        </ul>

                        <ul>
                            <li><h1>ESPACE CLIENT</h1></li>
                            <li><span>Mon accueil</span></li>
                            <li><span>Mes produits</span></li>
                            <li><span>Mes stats</span></li>
                            <li><span>Mes infos</span></li>
                            <br></br>
                            <li><h1>ASSISTANCE</h1></li>
                            <li><span>Questions réponses</span></li>
                            <li><span>Contact</span></li>
                            <li><span>Documents légaux</span></li>
                            <li><span>Cybersécurité</span></li>
                        </ul>

                        <ul>
                            <li><h1>À PROPOS</h1></li>
                            <li><span>Le blog Coyote</span></li>
                            <li><span>Qui sommes-nous ?</span></li>
                            <li><span>Nos engagements</span></li>
                            <li><span>Espace presse</span></li>
                            <li><span>Rejoignez-nous</span></li>
                        </ul>
                    </section>

                    <section>
                        <ul>
                            <li><span>Mentions légales</span></li>
                            <li><span>CGV</span></li>
                            <li><span>CGU</span></li>
                            <li><span>Charte site web</span></li>
                            <li><span>Politique des données personnelles</span></li>
                            <li><span>Gestion des cookies</span></li>
                            <li><span>© 2024-Coyote System</span></li>
                        </ul>
                    </section>
                </nav>
            </footer>
        </>
    );
};


export default Footer;