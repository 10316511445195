

function Home() {

  return (
      <>
        <center><br></br><h1>Notre site est en cours de constructions, veuillez revenir plus tard.</h1></center>
      </>
  );
}

export default Home;