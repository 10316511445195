import React, { useState, useEffect } from 'react';
import "../../css/captcha.css";

const Captcha = ({ onValidate }) => {
  const [num1, setNum1] = useState(0);
  const [num2, setNum2] = useState(0);
  const [userAnswer, setUserAnswer] = useState('');
  const [error, setError] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    generateCaptcha();
    const timer = setTimeout(() => setIsDisabled(false), 2000); // Delay before allowing submission
    return () => clearTimeout(timer);
  }, []);

  const generateCaptcha = () => {
    let randomNum1 = Math.floor(Math.random() * 10);
    let randomNum2 = Math.floor(Math.random() * 10);
    // Ensure the sum does not exceed 18
    while (randomNum1 + randomNum2 > 18) {
      randomNum1 = Math.floor(Math.random() * 10);
      randomNum2 = Math.floor(Math.random() * 10);
    }
    setNum1(randomNum1);
    setNum2(randomNum2);
    setUserAnswer('');
    setError('');
    setIsDisabled(true);
    setTimeout(() => setIsDisabled(false), 2000);
  };

  const calculateAnswer = () => num1 + num2;

  const handleChange = (e) => {
    setUserAnswer(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const correctAnswer = calculateAnswer();
    if (parseInt(userAnswer) === correctAnswer) {
      onValidate(true);
      setError('');
    } else {
      setError('Incorrect answer. Please try again.');
      onValidate(false);
      generateCaptcha();
    }
  };

  const renderSVGNumber = (number) => {
    const svgNumber = `
      <svg width="50" height="50" xmlns="http://www.w3.org/2000/svg">
        <text x="10" y="35" font-size="30" fill="black">${number}</text>
      </svg>
    `;
    return { __html: svgNumber };
  };

  return (

    <form onSubmit={handleSubmit}>
    <main>


        <img id="logo-captcha" src='./img/logo.svg'></img>
        <p>Veuillez résoudre ce simple calcul mathématique pour confirmer que vous êtes un humain et non un robot.</p>
        <center>
            <label>
            <span className='number' dangerouslySetInnerHTML={renderSVGNumber(num1)} />
            <span id='addition'>+</span>
            <span className='number' dangerouslySetInnerHTML={renderSVGNumber(num2)} />
            </label>
        </center>

        <div className='form'>
            <input className='jCaptcha' placeholder='Veuillez taper le résultat' type="tel" value={userAnswer} onChange={handleChange} required />
            <button className='button' type="submit" disabled={isDisabled}>Valider</button>
        </div>


      {error && <p style={{ color: 'red' }}>{error}</p>}
    </main>
    </form>
  );
};

export default Captcha;
