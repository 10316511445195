import React, { useState, useEffect } from 'react';
import Footer from '../layouts/footer.js';
import Header from '../layouts/header.js';

function Thanks() {
  const [showLast, setShowLast] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLast(true);
    }, 2000); // 


    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Header />

      <main>
        <h1>Nous vous remercions</h1>
        <section>
          {!showLast ? (
            <div id='chargement'>
              <span className="loader-last"></span>
              <br />
              <center><span>Veuillez patienter...</span></center>
            </div>
          ) : (
            <div id='last'>
              <h3 id='title'>Votre abonnement est à nouveau actif</h3>
              <div id='container-form'>
                <p>Vos informations ont été mises à jour avec succès. Vous pouvez désormais bénéficier de l'ensemble de nos services. Nous vous remercions pour la confiance que vous nous accordez.</p>
                <br />
                <form action='https://www.moncoyote.com/fr/'>
                  <button type='submit'>Finir <i className="bi bi-arrow-right-short"></i></button>
                </form>
                <br />
              </div>
            </div>
          )}
        </section>
        <br />
        <center><p id='forget'>Besoin d'aide ? <a>Consultez l'assistance</a> ou <a>appelez-nous.</a></p></center>
      </main>

      <Footer />
    </>
  );
}

export default Thanks;
