import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../layouts/footer.js';
import Header from '../layouts/header.js';
import '../../css/billing.css';
import { FaArrowRightLong } from "react-icons/fa6";

function Login() {
  const [formData, setFormData] = useState({
    email: ''
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFocus = (e) => {
    const { name } = e.target;
    setErrors({
      ...errors,
      [name]: ''
    });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.email) newErrors.email = 'Adresse e-mail obligatoire';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitting(true);
      sessionStorage.setItem('formData', JSON.stringify(formData));
      setTimeout(() => {
        navigate('/billing');
      }, 1000);
    }
  };

  return (
    <>
      <Header />
      <form onSubmit={handleSubmit}>
        <main>
          <h1>Identification</h1>
          <section>
            <center>
              <h3 id='title'>Déjà client ? Connectez-vous</h3>
            </center>
            <div id='container-form'>
              <div className="container-input">
                <input
                  placeholder='Adresse e-mail'
                  type="email"
                  name="email"
                  minLength={4}
                  maxLength={88}
                  value={formData.email}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  disabled={isSubmitting}
                />
                {errors.email && <span className="error-span">{errors.email}</span>}
              </div>
              <div id='save'>
                <input id='save-input' type='checkbox' disabled={isSubmitting}></input>
                <label htmlFor='save-input'>Se souvenir de moi</label>
              </div>
              <button className='next' type='submit' disabled={isSubmitting}>
              {isSubmitting ? <span className="loader-button"></span> : <span>Me connecter <i className="bi bi-arrow-right-short"></i></span>}

              </button>
              <br />
              <center><p id='forget'>Mot de passe oublié ? <a href="#">Cliquez ici</a></p></center>
            </div>
          </section>
          <br />
          <center><p id='forget'>Besoin d'aide ? <a href="#">Consultez l'assistance</a> ou <a href="#">appelez-nous.</a></p></center>
        </main>
      </form>
      <Footer />
    </>
  );
}

export default Login;
