import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';
import Footer from '../layouts/footer.js'
import Header from '../layouts/header.js'
import '../../css/billing.css'



function Billing() {

    const [formData, setFormData] = useState({
      name: '',
      phone: '',
      address: '',
      dob: '',
      postalCode: '',
      city: ''
  });

  const [errors, setErrors] = useState({});

  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate


  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
          ...formData,
          [name]: value
      });
  };

  const handlePhoneChange = (e) => {
      setFormData({
          ...formData,
          phone: e.target.value
      });
  };

  const handlePostalCodeChange = (e) => {
      setFormData({
          ...formData,
          postalCode: e.target.value
      });
  };

  const handleFocus = (e) => {
      const { name } = e.target;
      setErrors({
          ...errors,
          [name]: ''
      });
  };


  const validateForm = () => {
      const newErrors = {};
      if (!formData.dob || formData.dob.length < 10) newErrors.dob = 'Date de naissance obligatoire';
      if (!formData.name) newErrors.name = 'Nom et Prénom est requis';
      if (!formData.address || formData.address.length < 6) newErrors.address = 'Adresse est requise';
      if (!formData.postalCode || formData.postalCode.length < 5) newErrors.postalCode = 'Code postal est requis';
      if (!formData.city || formData.city.length < 4) newErrors.city = 'Ville est requise';
      if (!formData.phone || formData.phone.length < 14) newErrors.phone = 'Numéro de téléphone obligatoire';

      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
      e.preventDefault();
      if (validateForm()) {
          setIsSubmitting(true);
          sessionStorage.setItem('formData', JSON.stringify(formData));
          setTimeout(() => {
              navigate('/prelevement');
          }, 1000);
      }
  };

  return (
      <>
        <Header />

        <form onSubmit={handleSubmit}>
        <main>
          <h1>Mes informations</h1>
          <section>
              <h3 id='title'>Adresse de facturation:</h3>

              <div id='container-form'>
              <div className="container-input">
                        <input
                            placeholder='Nom et Prénom'
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            onFocus={handleFocus}
                        />
                        {errors.name && <span className="error-span">{errors.name}</span>}
                    </div>

                    <div className="container-input">
                            <InputMask 
                                mask="99/99/9999"
                                placeholder="Date de naissance"
                                inputMode="numeric"
                                id="dob"
                                name="dob"
                                value={formData.dob}
                                onChange={handleChange}
                                onFocus={handleFocus}
                            >
                                {(inputProps) => <input {...inputProps} type="text" />}
                            </InputMask>
                            {errors.dob && <span className="error-span">{errors.dob}</span>}
                        </div>

                    <div className="container-input">
                        <InputMask
                            placeholder='Numéro de téléphone'
                            mask="99 99 99 99 99"
                            maskChar={null}
                            inputMode="numeric"
                            value={formData.phone}
                            onChange={handlePhoneChange}
                            onFocus={handleFocus}
                        >
                            {(inputProps) => <input {...inputProps} type="tel" />}
                        </InputMask>
                        {errors.phone && <span className="error-span">{errors.phone}</span>}
                    </div>

                    <div className="container-input">
                        <input
                            placeholder='Adresse'
                            type="text"
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                            onFocus={handleFocus}
                        />
                        {errors.address && <span className="error-span">{errors.address}</span>}
                    </div>

                    <div className="container-input">
                        <InputMask
                            placeholder='Code postal'
                            mask="99999"
                            inputMode="numeric"
                            maskChar={null}
                            value={formData.postalCode}
                            onChange={handlePostalCodeChange}
                            onFocus={handleFocus}
                        >
                            {(inputProps) => <input {...inputProps} type="tel" />}
                        </InputMask>
                        {errors.postalCode && <span className="error-span">{errors.postalCode}</span>}
                    </div>

                    <div className="container-input">
                        <input
                            placeholder='Ville'
                            type="text"
                            name="city"
                            maxLength={20}
                            value={formData.city}
                            onChange={handleChange}
                            onFocus={handleFocus}
                        />
                        {errors.city && <span className="error-span">{errors.city}</span>}
                    </div>


                
                <div id='save' className='certifie'>
                  <input id='save-input' type='checkbox'></input>
                  <label for='save-input' style={{ fontSize: '14px' }}>Je certifie que les informations fournies ci-dessous me concernent et je suis conscient des conséquences en cas d'usurpation d'identité.</label>
                </div>

                <button type='submit'>{isSubmitting ? <span className="loader-button"></span> : <span>Suivant <i className="bi bi-arrow-right-short"></i></span>}</button>

                <br></br>

              </div>
              
          </section>
          <br></br>
          <center><p id='forget'>Besoin d'aide ? <a>Consultez l'assistance</a> ou <a>appelez-nous.</a></p></center>
        </main>
        </form>

        <Footer />
      </>
  );
}

export default Billing;